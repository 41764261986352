.background {
   background-color: #f7f7f7; 
   padding-top: 5%;
   text-align: center;
    display: block;
    justify-content: center;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 110%;
}

.login-container-success {
    padding-bottom: 4%;
}

.login-container-activate {
    padding-bottom: 2%;
    margin-bottom: 2% !important;
}
.login-container-password {
   padding-bottom: 6%;
}


/* Ventana pequeña vertical */
@media only screen and (min-height: 1px) {
    .login-container-small {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }

    .login-container-success {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-activate {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-password {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
}


/* Ventana mediana vertical */
@media only screen and (min-height: 500px) {
    .login-container-small {
        background-color: white;
        margin: auto;
        width: 50%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }

    .login-container-success {
        background-color: white;
        margin: auto;
        width: 50%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-activate {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-password {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
}

/* ventana grande vertical */
@media only screen and (min-height: 700px) {
    .login-container-small {
        background-color: white;
        margin: auto;
        width: 50%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-success {
        background-color: white;
        margin: auto;
        width: 50%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-activate {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-password {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
}

/* ventana grande completa */
@media only screen and (min-height: 700px) and (min-width: 1200px) {
    .login-container-small {
        background-color: white;
        margin: auto;
        width: 50%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-success {
        background-color: white;
        margin: auto;
        width: 50%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-activate {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
    .login-container-password {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
}

/* ventana tablet completa */
@media only screen and (min-height: 500px) and (min-width: 640px){
    .incorrect-password {
        margin-top: -10%;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1199px){
    .incorrect-password{
        margin-top: 4% !important;
    }
}

@media only screen and (min-width: 1200px){
    .underline-link {
        margin-top: 30px;
    }
    .incorrect-password{
        margin-top: 5% !important;
    }
    .login-button{
        padding-top: 5% !important;
        padding-bottom: 6% !important;
    }
}

@media only screen and (max-width: 720px){
    .underline-link {
        margin-right: 2%;
    }
}

@media only screen  and (min-width: 400px) and (max-width: 600px){
    .underline-link {
        margin-right: 5%;
    }
}


@media only screen and (min-width: 960px) and (max-width: 1033px){
    .cancel-password{
        margin-right: 3% !important;
        max-width: 0;
    }
}

/* .cancel-password{
    margin-right: 1% !important;
} */

@media only screen and (min-width: 800px) and (max-width: 1200px){
    .underline-link {
        margin-top: 25px;
    }
}

@media only screen and (min-width: 500px) and (max-width: 799px){
    .underline-link {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 1px) and (max-width: 500px){
    .underline-link {
        margin-top: 30px;
    }
}

/* @media only screen and (min-height: 724px) {
    .login-container-activate {
        background-color: white;
        margin: auto;
        width: 55%;
        height: auto;
        align-items: center;
        border-top-right-radius:15px;
        border-top-left-radius:15px;
        border-bottom-right-radius:15px;
        border-bottom-left-radius:15px;
    }
} */

.medium-container {
    width: 50%;
    height: 50%;
}




.logo {
    margin: auto;
    padding-bottom: 2%;
}

.input {
    width: 70%;
    height: 38px;
    font-size: 14px;
}

.title {
    color: #177acc;
    font-size: 22px;
    text-align: center;
    font-weight: 900;
    padding-top: 5%;
    padding-bottom: 5%;
}

.underline-link {
    font-size: 14px;
    font-weight: 500;
    height: 19px;
    text-align: center;
    text-decoration: underline;
    color: #37B5FF;
    cursor: pointer;
}

.container-end {
    text-align: right;
    height: 30px;
    align-content: flex-end;
    padding-right: 18% !important;
}

.button-container {
    text-align: right;
    /* height: 28px; */
    align-content: flex-end;
    /* padding-right: 18% !important; */
    position: relative;
    right: 14%;
}

.forgot-password-container {
    padding-right: 16% !important;
}

.button-container-create {
    text-align: right;
    height: 28px;
    align-content: flex-end;
    padding-right: 18% !important;
    padding-top: 0 !important;
}


.button-confirm {
    background-color: #37B5FF !important;
    height: 32px;
    color: white !important;
    font-size: 16px;
    width: 116px;
}

.button-cancel {
    height: 32px;
    color: #37b5ff !important;
    font-size: 16px;
    width: 116px;
}




.message {
    color: #858585;
    font-size: 14;

}

.bold {
    font-weight: 900;
}

.overlay-icon {
    margin-left: -39px !important;
}

.error-description {
    font-size: 14px;
    color: #f0b000;
    margin-top: 500px
}

.progress-bar {
    background-color: #f0b000;
}

.special-input {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 0;
}

.activate-bold {
    font-weight: 900;
}

.welcome{
    margin-left: 1% !important;
}

.welcome-validation{
    margin-left: 2% !important;
}

.message-container {
    text-align: left;
    padding-left: 16% !important;
}

.padding-message {
    padding-top: 2%;
}

.success-icon {
    height: 100px;
}

.button-ok-container {
    padding-top: 16%;
}

.instructions {
    text-align: center;
    padding-bottom: 4%;
}
